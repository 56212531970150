@font-face {
  font-family: "KGBlankSpaceSolid";
  src: url("../static/fonts/KGBlankSpaceSolid.ttf");
}

body,
html {
  width: 100vw;
  overflow-x: hidden;
  background: var("--chakra-colors-page-bg") !important;
  background-color: var("--chakra-colors-page-bg") !important;
  scroll-behavior: smooth;
}
