.noUi-target {
  background: #bbbbbb;
  border: 0;
  box-shadow: none;
}

.noUi-horizontal {
  height: 2px;
}

.noUi-connect {
  background: #faa61a;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  width: 0px;
  height: 0px;
  left: 0px;
  top: -10px;
}
.noUi-handle {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #faa61a;
  cursor: pointer;
  box-shadow: none;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-pips {
  color: #000;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: 00%;
  bottom: 120%;
}

.noUi-value {
  padding-top: 3px;
  font-size: 12px;
}

.noUi-marker-horizontal.noUi-marker {
  width: 1px;
  height: 3px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}

// .noUi-tooltip {
//   display: none;
// }
// .noUi-active .noUi-tooltip {
//   display: block;
// }

.noUi-horizontal .noUi-handle-lower .noUi-tooltip:before,
.noUi-horizontal .noUi-handle-upper .noUi-tooltip:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;

  background: #faa61a;
  margin-left: -12px;
  z-index: -1;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 10px;
  border-radius: 10px 10px 0 10px;
}

.noUi-tooltip {
  left: 50%;
  top: -36px;
  padding: 2px;
  width: 32px;
  height: 32px;
  margin-left: -2px;
  border: none;
  background: #faa61a;
  color: #fff;
  line-height: 28px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 400;
}

.noUi-pips-horizontal {
  height: 55px;
}

.noUi-handle {
  border: 0px !important;
  border-color: transparent !important;
}

.noUi-value {
  color: white;
}

.savingsMeterSliderWrapper {
  -webkit-transform: rotate(90deg) scaleX(-1);
  -moz-transform: rotate(90deg) scaleX(-1);
  -o-transform: rotate(90deg) scaleX(-1);
  -ms-transform: rotate(90deg) scaleX(-1);
  transform: rotate(90deg) scaleX(-1);

  .noUi-value.noUi-value-horizontal {
    -webkit-transform: rotate(90deg) scaleX(-1);
    -moz-transform: rotate(90deg) scaleX(-1);
    -o-transform: rotate(90deg) scaleX(-1);
    -ms-transform: rotate(90deg) scaleX(-1);
    transform: rotate(90deg) scaleX(-1);
    margin-top: 12px;
    margin-left: -6px;
  }

  .noUi-tooltip {
    -webkit-transform: rotate(90deg) scaleX(-1);
    -moz-transform: rotate(90deg) scaleX(-1);
    -o-transform: rotate(90deg) scaleX(-1);
    -ms-transform: rotate(90deg) scaleX(-1);
    transform: rotate(90deg) scaleX(-1);
    &::before {
      // -webkit-transform: rotate(90deg) scaleX(-1);
      // -moz-transform: rotate(90deg) scaleX(-1);
      // -o-transform: rotate(90deg) scaleX(-1);
      // -ms-transform: rotate(90deg) scaleX(-1);
      transform: rotate(135deg) !important;
      margin-left: 0 !important;
      margin-top: -6px !important;
      left: -2.5px !important;
    }
  }
}

.savingsMeterSliderWrapper2 {
  .noUi-horizontal {
    height: 4px;
  }
}

.questionMark {
  -webkit-transform: rotate(90deg) scaleX(-1);
  -moz-transform: rotate(90deg) scaleX(-1);
  -o-transform: rotate(90deg) scaleX(-1);
  -ms-transform: rotate(90deg) scaleX(-1);
  transform: rotate(90deg) scaleX(-1);
}

.slider23 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.pointer {
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}
